// Override variables
$primary-base: #2f80ed !default;
$primary-dark: darken($primary-base, 10%) !default;
$primary-50: lighten($primary-base, 50%) !default;
$primary-25: lighten($primary-base, 25%) !default;

@import "~@coreui/coreui/scss/coreui";

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}
