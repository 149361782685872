.custom-input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  outline: none;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + {
    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: block;
      overflow: hidden;
      padding: 0.625rem 1.25rem;
      border: 1px solid #e2e8f0;
      border-radius: 0.375rem;
      color: #4a5568;
      background-color: $white;
      outline: none;
      margin: 0;
      i {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        fill: currentColor;
        margin-top: -0.25em;
        margin-right: 0.5em;
      }
      &:hover {
        background-color: $white;
      }
    }
  }
  &:focus {
    + {
      label {
        background-color: $white;
      }
    }
  }
}
.custom-input-file.has-focus {
  + {
    label {
      background-color: $white;
    }
  }
}
.no-js {
  .custom-input-file {
    + {
      label {
        display: none;
      }
    }
  }
}
