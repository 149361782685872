//
// Input group
//

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  > .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    + {
      .form-control {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .custom-file {
        margin-left: -1px;
      }
    }
    &:focus {
      z-index: 3;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    + {
      .form-control {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .custom-file {
        margin-left: -1px;
      }
    }
  }
  > .custom-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    + {
      .form-control {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .custom-file {
        margin-left: -1px;
      }
    }
    &:focus {
      z-index: 3;
    }
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    + {
      .form-control {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .custom-file {
        margin-left: -1px;
      }
    }
    .custom-file-input {
      &:focus {
        ~ {
          .custom-file-label {
            z-index: 3;
          }
        }
        z-index: 4;
      }
    }
    &:not(:last-child) {
      .custom-file-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &::after {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    &:not(:first-child) {
      .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  > .input-group-prepend {
    > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    > .input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:not(:first-child) {
      > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      > .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:first-child {
      > .btn {
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      > .input-group-text {
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  > .input-group-append {
    &:not(:last-child) {
      > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      > .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child {
      > .btn {
        &:not(:last-child) {
          &:not(.dropdown-toggle) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      > .input-group-text {
        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    > .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.input-group-merge {
    .input-group-text {
      color: $input-color;
      background-color: $white;
      border: $input-border-width solid $input-border-color;
    }
    .form-control {
      box-shadow: none;
    }
    .form-control:not(.form-control-prepend) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
      padding-right: 0;
    }
    .form-control + .input-group-append {
      margin-left: -1px;
    }
    .form-control:focus + .input-group-prepend .input-group-text,
    .form-control:focus ~ .input-group-append .input-group-text {
      border-color: $input-group-addon-focus-border-color;
    }
    .form-control-prepend {
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
      padding-left: 0;
    }
    .form-control-prepend + .input-group-prepend {
      order: -1;
      margin-right: -1px;
    }
    .form-control-prepend + .input-group-prepend > .input-group-text {
      border-right: 0;
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
    .form-control-append {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
      padding-right: 0;
    }

    &.rounded-pill .form-control:not(.form-control-prepend) {
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
    }

    &.rounded-pill .form-control + .input-group-append > .input-group-text {
      border-top-right-radius: 50rem;
      border-bottom-right-radius: 50rem;
    }

    &.rounded-pill .form-control-prepend {
      border-top-right-radius: 50rem;
      border-bottom-right-radius: 50rem;
    }
    &.rounded-pill .form-control-prepend + .input-group-prepend > .input-group-text {
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
    }
  }
}

.input-group-prepend {
  display: flex;
  margin-right: -1px;
  .btn {
    position: relative;
    z-index: 2;
    &:focus {
      z-index: 3;
    }
    + {
      .btn {
        margin-left: -1px;
      }
      .input-group-text {
        margin-left: -1px;
      }
    }
  }
  .input-group-text {
    + {
      .input-group-text {
        margin-left: -1px;
      }
      .btn {
        margin-left: -1px;
      }
    }
  }
}
.input-group-append {
  display: flex;
  margin-left: -1px;
  .btn {
    position: relative;
    z-index: 2;
    &:focus {
      z-index: 3;
    }
    + {
      .btn {
        margin-left: -1px;
      }
      .input-group-text {
        margin-left: -1px;
      }
    }
  }
  .input-group-text {
    + {
      .input-group-text {
        margin-left: -1px;
      }
      .btn {
        margin-left: -1px;
      }
    }
  }
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $input-color;
  text-align: center;
  white-space: nowrap;
  background-color: #fafbfe;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  transition: $input-transition;
  input[type="radio"] {
    margin-top: 0;
  }
  input[type="checkbox"] {
    margin-top: 0;
  }
}

.input-group-addon {
  @extend .input-group-text;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  + .form-control {
    border-left: 0;
    padding-left: 0px;
  }
  .btn {
    padding: 0.75rem 1.25rem;
  }
}

.input-group-lg {
  > .form-control {
    &:not(textarea) {
      height: calc(1.5em + 2rem + 2px);
    }
    padding: 1rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: $input-border-radius-lg;
  }
  > .custom-select {
    height: calc(1.5em + 2rem + 2px);
    padding: 1rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.5rem;
    padding-right: 2.25rem;
  }
  > .input-group-prepend {
    > .input-group-text {
      padding: 1rem 1.875rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: $input-border-radius-lg;
    }
    > .btn {
      padding: 1rem 1.875rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: $input-border-radius-lg;
    }
  }
  > .input-group-append {
    > .input-group-text {
      padding: 1rem 1.875rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: $input-border-radius-lg;
    }
    > .btn {
      padding: 1rem 1.875rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: $input-border-radius-lg;
    }
  }
}
.input-group-sm {
  > .form-control {
    &:not(textarea) {
      height: calc(1.5em + 1rem + 2px);
    }
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  > .custom-select {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: $input-border-radius-sm;
    padding-right: 2.25rem;
  }
  > .input-group-prepend {
    > .input-group-text {
      padding: 0.5rem 1.25rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: $input-border-radius-sm;
    }
    > .btn {
      padding: 0.5rem 1.25rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: $input-border-radius-sm;
    }
  }
  > .input-group-append {
    > .input-group-text {
      padding: 0.5rem 1.25rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: $input-border-radius-sm;
    }
    > .btn {
      padding: 0.5rem 1.25rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: $input-border-radius-sm;
    }
  }
}

// Flush

.input-group-flush {
  .form-control,
  .input-group-text {
    border: 0 !important;
  }
}

// Focused state

.focused {
  // Add shadow on the input group (not on .form-control)
  .input-group {
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }

    .form-control {
      box-shadow: none !important;
    }
  }

  // Additional styles for the input addon element
  .input-group-text {
    color: $input-group-addon-focus-color;
    background-color: $input-group-addon-focus-bg;
    border-color: $input-group-addon-focus-border-color;
  }
}
